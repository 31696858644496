import { AgEditorComponent } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { barCodeFormatValidator, barCodeValidator, GLOBAL_CONSTANTS } from '@apx-ui/apx-core';

import { ReportValidatorsService } from '../../../services';

interface InitParams extends ICellEditorParams {
  validators?: any[] | any;
  category: string;
}

@Component({
  selector: 'apx-ui-shared-label-id-cell',
  templateUrl: './label-id-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelIdCellComponent implements AgEditorComponent {
  form: UntypedFormGroup;
  params: InitParams;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private readonly reportValidator: ReportValidatorsService,
  ) {
  }

  get inputCtrl(): AbstractControl {
    return this.form.get('input');
  }

  agInit(params: any): void {
    this.params = params;

    const validator = typeof this.params.validators === 'function'
      ? this.params.validators(params)
      : this.params.validators ?? [];

    this.form = this.formBuilder.group({
      input: [
        params.value,
        [
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(16),
          barCodeValidator(GLOBAL_CONSTANTS.REPORT_LABEL_START_YEAR, GLOBAL_CONSTANTS.REPORT_LABEL_END_YEAR),
          barCodeFormatValidator(),
          ...validator,
        ],
        [
          this.reportValidator.asyncMaxLabelIdValueValidator(params),
          this.reportValidator.isUsedLabelIdValidator(params),
        ],
      ],
    });

    if (params.value) {
      this.inputCtrl.markAsTouched();
      this.inputCtrl.markAsDirty();
    }
  }

  getValue(): any {
    if(!this.inputCtrl.invalid) {
      this.params.data.incorectLabel = false;
    }
    return this.inputCtrl.value;
  }

  getErrorMessage(inputCtrl: AbstractControl): string {
    if(Object.entries(inputCtrl.errors).length > 0) {
      this.params.data.incorectLabel = true;
    } else {
      this.params.data.incorectLabel = false;
    }

    for (const [key] of Object.entries(inputCtrl.errors)) {

      switch (key) {
        case 'required': {
          return `${this.params.colDef.headerName} is required.`;
        }
        case 'minlength': {
          return 'Incomplete Label ID';
        }
        case 'maxlength': {
          return 'Invalid Label ID';
        }
        case 'notBarcodeFormat':
        case 'invalidBarCode':
        case 'maxLabelIdValue':
        case 'incorrectLableId': {
          return 'Incorrect Label ID.';
        }
        default: {
          return `Validation error for ${this.params.colDef.headerName}.`;
        }
      }

    }

    return '';
  }

}
